
export const EnumCodeScreen = {
    CadastroConsultorio: 1,
    CadastroCliente: 2,
    CadastroProfissionalSaude: 3,
    CadastroPerfil: 4,
    CadastroCentroCustos: 5,
    CadastroProcedimento: 6,
    CadastroProduto: 7,
    CadastroFornecedor: 8,
    CadastroMedicamentos: 9,
    CadastroSalas: 10,
    CadastroAvaliacoes: 11,
    Financeiro: 12,
    Estoque: 13,
    ConsultasTratamento: 14,
    CadastrosTratamento: 15,
    Agenda: 16,
    Dashboard: 17,
    CadastroGrupodeProcedimento: 18,
    Atestado: 19,
    EmissaoAtestado: 20,
    CadastroUsuarios: 21,
    ConsultaAnamnese: 22,
    CadastroAnamnese: 23,
    Recibo: 24,
    Receituario: 25,
    PreAgendamento: 26,
    CadastroContaFinanceira: 27,
    CadastroPromocao: 28,
    CadastroConfiguracoes: 29,
    CadastroProcedimentoHof: 30,
    CadastroFormaPagamento: 31,
    Nfse: 32,
    PacoteDeProcedimentos: 33,
    VendaPacote: 34,
    VendaProduto: 35,
    Reports: 36,
    DadosNfse: 37,
    CentralDeAtendimento: 38,
    PerguntasFrequentes: 39,
    MeuPlano: 40,
    ClientesAusentes: 41,
    Aniversarios: 42,
    SurveySettings: 43,
    SurveyResults: 44,
    financialSummary: 45,
    professionalFinances: 46,
    ReportFormOsPayment: 47,
    TreatmentReport: 48,
    ReportFinancialAccount: 49,
    PackageReport: 50,
    ProductsSoldReport: 51,
    CelcashFinancialTransaction: 52
}